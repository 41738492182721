import { h, render } from "preact";
import Widget from "./widget.js";
import { defaultConfiguration } from "./default-configuration.js";

if (window.attachEvent) {
  window.attachEvent("onload", injectChat);
} else {
  window.addEventListener("load", injectChat, false);
}

function injectChat() {
  const config = window.matrixlivechat;
  if (!config?.server) {
    console.error("Please set window.server");
  } else {
    const root = document.createElement("div");
    root.id = "matrixLiveChatRoot";
    document.getElementsByTagName("body")[0].appendChild(root);
    const iFrameSrc = config.server + "/chat.html";
    const host = window.location.host || "unknown-host";

    render(
      <Widget
        host={host}
        isMobile={window.screen.width < 500}
        iFrameSrc={iFrameSrc}
        conf={defaultConfiguration}
        chatId={config?.chatId}
      />,
      root
    );
  }
}

window.injectChat = injectChat;
